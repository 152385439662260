import React from "react";
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
// import Home from "./pages";
// import About from "./pages/about";
// import ProjectSpaceship from "./pages/project_spaceship";
// import Cellumancer from "./pages/cellumancer";
// import Converter from "./pages/converter";

// import GUGHome from "./pages/gug_home";
// import Forge from "./pages/gug_forge";
// import Homev2 from "./pages/gug_home_v2";
import RiveHome from "./pages/gug_home_rive";
// import RiveHomeSelfRendered from "./pages/gug_home_rive_self_rendered";
// import Register from "./pages/gug_register";
// import ContentCreatorWidget from "./pages/content_creator_widget";
 
function App() {
    return (
        <Router>
            {/* <Navbar /> */}
            <Routes>
                <Route exact path="/" element={<RiveHome />} />
                {/* <Route exact path="/" element={<RiveHomeSelfRendered />} /> */}
                {/* <Route exact path="/" element={<Homev2 />} /> */}
                {/* <Route exact path="/" element={<Forge />} /> */}
                {/* <Route exact path="/" element={<ContentCreatorWidget />} /> */}
                {/* <Route exact path="/register" element={<Register />} /> */}
                {/* <Route exact path="/forge" element={<Forge />} /> */}
                {/* <Route exact path="/" element={<Home />} /> */}
                {/* <Route path="/about" element={<About />} />
                <Route path="/project-spaceship" element={<ProjectSpaceship />} />
                <Route path="/cellumancer" element={<Cellumancer />} />
                <Route path="/converter" element={<Converter />} /> */}
            </Routes>
        </Router>
    );
}
 
export default App;
import './App.css';

import React, { useRef, useEffect, useState } from "react";
import { useRive, Layout, Fit, Alignment, decodeImage, RiveEventType, EventType } from "@rive-app/react-canvas";

const ENDPOINT_PATH = "https://gugappletbackend-production.up.railway.app/";

let i = 0;
export const RiveDemo = () => {
  const [imageAsset, setImageAsset] = useState();
  const [images, setImages] = useState();
  const [names, setNames] = useState();
  const [ids, setIds] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    fetch(ENDPOINT_PATH + "get_gugs_count_new")
    .then((res) => res.text())
    .then((value) => {
      setCount(value);
    });

    const interval = setInterval(
      () => {
        fetch(ENDPOINT_PATH + "get_gugs_count_new")
        .then((res) => res.text())
        .then((value) => {
          setCount(value);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch(new Request(`gugs/gug_config.json`)).then(resp => resp.json()).then(obj => {
      const NUM_FILES = obj["names"].length
      
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]; // Swap
        }
      }
      const array = [...Array(NUM_FILES).keys()];
      shuffleArray(array);
      const randomIndices = array.slice(0, 10);

      const files = randomIndices.map((j) => obj["files"][j])
      const names = randomIndices.map((j) => obj["names"][j])
      const ids = randomIndices.map((j) => obj["ids"][j])

      setNames(names);
      setIds(ids);

      Promise.all(
        files.map(
          (file) => fetch(new Request(`gugs/${file}`)).then(async resp => {
            const bytes = new Uint8Array(await resp.arrayBuffer())
            const decodedImage = await decodeImage(bytes)
            return decodedImage
          })
          // TODO: On missing file we hang indefinitely
          // .catch((e) => console.log(e))
        )
      ).then((results) => {
        setImages(results);
      })
    });
  }, []);

  const { rive, setCanvasRef, canvas: canvasRef, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "static_scene.riv",
    // src: "clean_the_car.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    artboard: "LandingPage",
    isTouchScrollEnabled: true,
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.Fill, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.TopCenter,
    }),
    autoplay: true,
    // onAdvance: (event) => {
    //   const dt = event.data
    //   // console.log(dt)
    //   console.log(canvasRef)
    //   if (canvasRef) {
    //     const ctx = canvasRef.getContext("2d")
    //     ctx.fillStyle = "red";
    //     ctx.fillRect(0, 0, 150, 75);
    //   }
    // },
    // automaticallyHandleEvents: true, // Automatically handle RiveHTTPEvents
    // onLoad: () => {
    //   // Prevent a blurry canvas by using the device pixel ratio
    //   riveInstance.resizeDrawingSurfaceToCanvas();
    // },
    assetLoader: (asset, bytes) => {
      if (asset.isImage) {
        // console.log(asset.name)
        if (asset.name === 'green_gug') {
            setImageAsset(asset);
            // Keep the original while we load the new ones
            return false;
        }
        // etc
        return false;
      }
      return false;
    },
  });

  // Wait until the rive object is instantiated before adding the Rive
  // event listener
  useEffect(() => {
    if (!rive || !images) return
    const onRiveEventReceived = (riveEvent) => {
      const eventData = riveEvent.data;
      const eventProperties = eventData.properties;
      if (eventData.type === RiveEventType.General) {
        // console.log("Event name", eventData.name);
        // Added relevant metadata from the event
        // console.log("Rating", eventProperties.rating);
        // console.log("Message", eventProperties.message);
        if (images) {
          rive.setTextRunValueAtPath("textLoading", names[i], "Radio");
          rive.setTextRunValueAtPath("textID", ` #${ids[i]}`, "Radio");
          imageAsset.setRenderImage(images[i]);
          i = (i + 1) % images.length
        }
        // IMPORTANT: call unref, so that we do not keep the asset alive with
        // a reference from javascript.
        // latestImage.unref();
  
      } else if (eventData.type === RiveEventType.OpenUrl) {
        console.log("Event name", eventData.name);
  
        let url = '';
        if (eventData.name == "DemoLink") {
          url = "https://store.steampowered.com/app/3256600/GUG_Demo/";
        } else if (eventData.name == "DiscordLink") {
          url = "https://discord.com/invite/VkbF6SdBta"
        } else if (eventData.name == "SteamLink") {
          url = "https://store.steampowered.com/app/2824790/GUG/";
        }
        window.open(url, '_blank').focus();
        // Handle OpenUrl event manually
        // window.location.href = data.url;
      }
    };

    rive.on(EventType.RiveEvent, onRiveEventReceived);
    // No longer needed, it comes pre-baked in the file
    // rive.setTextRunValueAtPath("textLoading", "Fire Gug", "Radio");
  }, [rive, images])

  if (rive && count) {
    rive.setTextRunValueAtPath("textGugCount", count, "Radio");
  }

  useEffect(() => {
    if (rive) {
      // Resize the drawing surface if the window resizes
      const resizeListener = window.addEventListener(
        "resize",
        () => {
          // TODO: This could be a top-level useStateMachineInput like in the AP
          const inputs = rive.stateMachineInputs('State Machine 1');
          // Find the input you want to set a value for, or trigger
          if (inputs) {
            const widthNum = inputs.find(i => i.name === 'widthNum');
            const value = window.innerWidth / window.innerHeight * 1080;
            widthNum.value = value
          }
        },
        false
      );
      // TODO: This could be a top-level useStateMachineInput like in the AP
      const inputs = rive.stateMachineInputs('State Machine 1');
      // Find the input you want to set a value for, or trigger
      if (inputs) {
        const widthNum = inputs.find(i => i.name === 'widthNum');
        const value = window.innerWidth / window.innerHeight * 1080;
        widthNum.value = value;
      }

      return () => window.removeEventListener("resize", resizeListener);
    }
  }, [rive]);
  
  // return <canvas ref={setCanvasRef} />;
  return (<RiveComponent />);
};

function LandingPage(props) {
  return (
    <div className="App">
      {/* <div style = {{width: "30%", height: "40vh", backgroundColor: "red", position: "absolute", top:"10vh", left: "20%"}} /> */}
      <div className="RiveContainer" style={{height: "200vh"}}>
        <RiveDemo />
      </div> 
      {/* <canvas id="rive-canvas" style={{position: "absolute", zIndex: 10, top: 100, left: 0}} /> */}
      {/* <div style = {{width: "30%", height: "40vh", backgroundColor: "green", position: "absolute", top:"10vh", left: "60%"}} /> */}
    </div>
  );
}

export default LandingPage;